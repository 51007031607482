import React from "react";
import "./content-switcher.less";
import asciiFrames from "../assets/shortascii.json";
import { AsciiVideo } from "../assets/asciiVideo";
// import { BlogPost } from "./portfolio-entries/BlogPost";
import {
  ALL_ENTRIES,
  Entries,
  meta,
} from "../assets/portfolio-entries/blog-content.constants";
import { BlogContent } from "../assets/portfolio-entries/BlogContent";

export const ContentSwitcher = () => {
  const [hoveredProject, setHoveredProject] = React.useState<Entries | null>(
    null
  );

  const images =
    hoveredProject != null
      ? meta[hoveredProject]["imagePaths"].map((imagePath) => {
          console.log("yo", imagePath);
          return <img src={imagePath} key={imagePath} />;
        })
      : null;

  return (
    <div className="content-switcher">
      {/* <div className="projects">
        <ul>
          {ALL_ENTRIES.map((title) => {
            return (
              <li
                key={title}
                onMouseEnter={() => setHoveredProject(title as Entries)}
                className={`clickable ${
                  title === hoveredProject ? "selected" : ""
                }`}
                role="button"
                tabIndex={0}
                onClick={() => setHoveredProject(title as Entries)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setHoveredProject(title as Entries);
                  }
                }}
              >
                {title}
              </li>
            );
          })}
        </ul>
      </div> */}
      <div className="content">
        {hoveredProject != null && meta[hoveredProject] != null ? (
          <div className="blog-container">
            <BlogContent
              title={hoveredProject}
              description={meta[hoveredProject]["description"]}
              images={images != null ? images : undefined}
              link={meta[hoveredProject]["link"]}
              year={parseInt(meta[hoveredProject]["year"])}
            />
          </div>
        ) : (
          <div className="blog-container">
            <div className="blog-post">
              <AsciiVideo asciiFrames={asciiFrames} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
