import React from "react";

import { AsciiVideo } from "./assets/asciiVideo";
import { StrictMode } from "react";

import "./homepage.less";

import { ContentSwitcher } from "./ContentSwitcher/ContentSwitcher";

export const Homepage = () => {
  return (
    <>
      <div className="background-pixels" />

      <div className="background-arrows"></div>

      <div className="portfolio">
        <div className="flex">
          <div className="introduction">
            <span className="text">Jack Rybarczyk, </span>
            <span className="highlighted">online </span>
          </div>

          <ContentSwitcher />
        </div>
        {/* <Canvas /> */}
      </div>
    </>
  );
};

export default Homepage;
