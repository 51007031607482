import sculptureGif1 from "../portfolio-entries/blog-assets/arduino-sculpture-5.gif";
import sculptureGif2 from "../portfolio-entries/blog-assets/arduino-sculpture-3.gif";
import asciiSiteGif from "../portfolio-entries/blog-assets/ascii-site-gif2.gif";
import dogs1 from "../portfolio-entries/blog-assets/dogs-01.png";
import dogs2 from "../portfolio-entries/blog-assets/dogs-02.png";
import dogs3 from "../portfolio-entries/blog-assets/dogs-03.png";
import fakeAd1 from "../portfolio-entries/blog-assets/fake_ad_1.png";
import fakeAd2 from "../portfolio-entries/blog-assets/fake_ad_2.gif";
import whitePoster from "../portfolio-entries/blog-assets/white-poster.png";
import reddPoster from "../portfolio-entries/blog-assets/redd-poster.png";
import tees from "../portfolio-entries/blog-assets/tees.png";
import book from "../portfolio-entries/blog-assets/book.jpg";

interface PortfolioEntry {
  year: string;
  imagePaths: string[];
  link?: string;
  description: string;
}

export type Entries =
  | "tools for designers"
  | "interactive sculpture"
  | "dogs"
  | "fake ads"
  | "selected design"
  | "computational tees";

export const ALL_ENTRIES: Entries[] = [
  "tools for designers",
  "interactive sculpture",
  "dogs",
  "fake ads",
  "selected design",
  "computational tees",
];

export const meta: Record<Entries, PortfolioEntry> = {
  "tools for designers": {
    year: "2023",
    imagePaths: [asciiSiteGif],
    link: "https://rybarczykj.github.io/ascii/",
    description:
      "A tool to generate ascii art for photos and videos and edit it on the fly. The only such tool to visualize changes in resolution and contrast in real time.",
  },
  "interactive sculpture": {
    year: "2022",
    imagePaths: [sculptureGif1, sculptureGif2],
    link: "https://www.youtube.com/watch?v=6a1oaH573dY&list=PLZeTy8TIwguGbXbmBebhnmyQDMBwzvWZL&index=1",
    description:
      "Using bodies as controllers, I created a series of sculptures that respond to the proximity of the user.",
  },
  dogs: {
    year: "2022",
    imagePaths: [dogs1, dogs2, dogs3],
    description: "you wouldn't download a dog",
  },
  "fake ads": {
    year: "2022",
    imagePaths: [fakeAd2, fakeAd1],
    description: "I made some fake ads",
  },
  "selected design": {
    year: "2022+",
    imagePaths: [whitePoster, reddPoster],
    description: "just some posters",
  },
  "computational tees": {
    year: "2021",
    imagePaths: [tees, book],
    link: "https://www.jackrybarczyk.com/cs318/318.html",
    description:
      "Created various computational media works, including t-shirts whose designs were suggested by a grammar.",
  },
};
