import React from "react";

// takes a string for title
// but a ReactNode for description and images
export const BlogContent = ({
  title,
  description,
  images,
  link,
  year,
}: {
  title: string;
  description: React.ReactNode;
  images?: JSX.Element[];
  link?: string;
  year?: number;
}) => {
  return (
    <div className="blog-post">
      <div className="content-text">
        <div className={"title"}>
          {title}
          {link && (
            <div className={"link"}>
              <a href={link}>
                {
                  //only show the first 30 characters of the link
                  link.slice(0, 45) + (link.length > 45 ? "..." : "")
                }
              </a>
            </div>
          )}
        </div>

        <div className={"description"}>{description}</div>
      </div>
      <div className="blog-images">
        {images &&
          images.map((image, i) => (
            <div className="blog-image" key={i}>
              {image}
            </div>
          ))}
      </div>
    </div>
  );
};
